import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class CookiesService {
  constructor(private cookieService: CookieService) {}

  check(key: string): boolean {
    return this.cookieService.check(key);
  }

  get(key: string) {
    return this.check(key) ? JSON.parse(this.cookieService.get(key)) : null;
  }

  set(key: string, value: any) {
    this.cookieService.set(key, JSON.stringify(value));
  }

  delete(key: string) {
    this.cookieService.delete(key);
  }

  deleteAll() {
    this.cookieService.deleteAll();
  }
}
